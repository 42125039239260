@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
}

.zoominoutsinglefeatured {
	animation: zoominoutsinglefeatured 1s infinite ;
}